import React from 'react'
import { Message } from 'semantic-ui-react'
import CompanyInfo from 'components/CompanyInfo'

export default function InactiveCompany(props) {
  return (
    <div>
      <CompanyInfo company={props.company} hideAdditionalInfo />
      <Message color="red" size="big" style={{ textAlign: 'center' }}>
        <Message.Header>
          Ticket sales are not currently available.
        </Message.Header>
        <p>Please check back later.</p>
      </Message>
    </div>
  )
}
