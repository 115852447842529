import React from 'react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { Form, Message } from 'semantic-ui-react'
import Wrapper from 'components/Wrapper'

export default class RequestRefund extends React.Component {
  constructor() {
    super()
    this.state = {
      reason: '',
      loading: false,
      processed: false
    }
    this.requestRefund = this.requestRefund.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  requestRefund() {
    if (this.state.loading) return
    if (!this.state.reason) {
      return toast.error('Please enter a reason for requesting a refund.')
    }

    axios()
      .patch(`/transactions/request-refund/${this.props.match.params.id}`, {
        reason: this.state.reason
      })
      .then(res => {
        this.setState({ processed: true })
      })
      .catch(error => {
        toast.error(error.message)
        this.setState({ loading: false })
      })
  }

  render() {
    if (this.state.processed) {
      return (
        <Wrapper>
          <Message size="large">Your request has been processed.</Message>
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Form>
          <Form.TextArea
            label="Why are you requesting a refund?"
            fluid
            name="reason"
            onChange={this.handleInputChange}
          />
          <Form.Button
            loading={this.state.loading}
            fluid
            onClick={this.requestRefund}
          >
            Request a Refund
          </Form.Button>
        </Form>
      </Wrapper>
    )
  }
}
