import React, { Component } from 'react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import moment from 'moment-timezone'
import TicketTypes from 'components/TicketTypes'
import Calendar from 'components/Calendar'
import { Element, scroller } from 'react-scroll'
import { Message } from 'semantic-ui-react'
import CompanyInfo from 'components/CompanyInfo'

export default class TimeSlotSelector extends Component {
  constructor() {
    super()
    this.state = {
      timeSlots: [],
      selectedDay: null,
      selectedMonth: moment(),
      loading: false,
      allTicketTypes: []
    }
    this.addMonth = this.addMonth.bind(this)
    this.subtractMonth = this.subtractMonth.bind(this)
    this.getTimeSlots = this.getTimeSlots.bind(this)
    this.moveToCheckout = this.moveToCheckout.bind(this)
    this.selectDate = this.selectDate.bind(this)
  }

  componentDidMount() {
    this.getTimeSlots()
    axios()
      .get(`/ticket-types/${this.props.company.id}`)
      .then(allTicketTypes => this.setState({ allTicketTypes }))
      .catch(e => toast.error(e.message))
  }

  getTimeSlots() {
    this.setState({ timeSlots: [], loading: true })
    axios()
      .get(`/time-slots/${this.props.company.id}`, {
        params: {
          month: this.state.selectedMonth.month() + 1,
          year: this.state.selectedMonth.year()
        }
      })
      .then(timeSlots => this.setState({ timeSlots, loading: false }))
      .catch(e => toast.error(e.message))
  }

  addMonth() {
    this.setState(
      {
        selectedMonth: this.state.selectedMonth.add(1, 'month'),
        selectedDay: null
      },
      this.getTimeSlots
    )
  }

  subtractMonth() {
    this.setState(
      {
        selectedMonth: this.state.selectedMonth.subtract(1, 'month'),
        selectedDay: null
      },
      this.getTimeSlots
    )
  }

  moveToCheckout(ticketType, timeSlot) {
    this.props.history.push({
      pathname: `/${this.props.company.frontEndUrl}/checkout`,
      state: {
        ticketType,
        timeSlot
      }
    })
  }

  selectDate(selectedDay) {
    this.setState({ selectedDay })
    scroller.scrollTo('TicketTypes', {
      duration: 500,
      delay: 100,
      smooth: true
    })
  }

  render() {
    const {
      timeSlots,
      selectedDay,
      selectedMonth,
      loading,
      allTicketTypes
    } = this.state

    let timeSlotsForDay = []
    if (selectedDay) {
      timeSlotsForDay = timeSlots.filter(timeSlot =>
        selectedDay.isBetween(timeSlot.startAt, timeSlot.endAt, 'day', '[]')
      )
    }
    return (
      <div>
        <CompanyInfo company={this.props.company} />
        <h3 style={{ textAlign: 'center' }}>
          Select a highlighted date from the calendar below to view available
          time slots.
        </h3>
        <Calendar
          timeSlots={timeSlots}
          month={selectedMonth}
          onNext={this.addMonth}
          onPrev={this.subtractMonth}
          selectedDay={selectedDay}
          onSelect={this.selectDate}
          loading={loading}
        />
        <Element name="TicketTypes" />
        {selectedDay && (
          <Message size="small">
            Select a time slot and ticket type from the list below. If you need
            to purchase multiple types of tickets you will need to make multiple
            purchases. <strong>*{this.props.company.timeZone} Time Zone</strong>
          </Message>
        )}
        {timeSlotsForDay.map(timeSlot => (
          <TicketTypes
            key={timeSlot.id}
            timeSlot={timeSlot}
            ticketTypes={allTicketTypes}
            onSelect={this.moveToCheckout}
          />
        ))}
      </div>
    )
  }
}
