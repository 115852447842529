import React from 'react'
import { Message, Divider } from 'semantic-ui-react'
import styled from 'styled-components'
import Linkify from 'react-linkify'

const Logo = styled.img`
  max-width: 100%;
  max-height: 200px;
  display: block;
  margin: auto;
  @media print {
    max-height: 100px;
  }
`
const Head = styled.h1`
  margin: 20px 0;
  text-align: center;
  font-size: 24px;
  @media (max-width: 500px) {
    font-size: 20px;
  }
  @media print {
    display: none;
  }
`

export default ({ company, hideAdditionalInfo, header }) => {
  return (
    <div>
      {company.logoUrl && <Logo src={company.logoUrl} alt={company.name} />}
      <Head>{header || <span>Buy tickets for the {company.name}!</span>}</Head>
      {!hideAdditionalInfo && company.additionalInfo && (
        <Message style={{ textAlign: 'center', whiteSpace: 'pre-wrap' }}>
          <Linkify properties={{ target: '_blank' }}>
            {company.additionalInfo}
          </Linkify>
        </Message>
      )}
      <Divider />
    </div>
  )
}
