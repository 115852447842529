import React from 'react'
import { Table, Icon, Loader } from 'semantic-ui-react'
import moment from 'moment-timezone'
import styled from 'styled-components'

const Wrapper = styled.div`
  position: relative;
`
const InnerCell = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${props =>
    props.selected ? '#666666' : props.active && '#4d4d4d'};
  &:hover {
    background: #666666;
  }
`
const MonthSelector = styled.div`
  display: flex;
  justify-content: space-between;
  font-size: 16px;
  width: 180px;
  margin: auto;
  margin-top: 18px;
  @media (max-width: 600px) {
    margin-top: 8px;
  }
`
const Days = styled.div`
  display: flex;
  padding: 12px 0 5px 0;
`
const Day = styled.div`
  width: 14.286%;
  text-align: center;
`

export default function Calendar(props) {
  const daysInMonth = props.month.daysInMonth()
  const paddingDays = props.month.startOf('month').day()
  const now = moment()
  return (
    <Wrapper>
      <Loader active={props.loading} inverted />

      <Table
        textAlign="center"
        celled
        columns={7}
        unstackable
        compact="very"
        fixed
        inverted
      >
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell colSpan="7" style={{ padding: 0 }}>
              <MonthSelector>
                <Icon
                  name="caret left"
                  fitted
                  onClick={props.onPrev}
                  style={{
                    cursor: 'pointer',
                    visibility: props.month.isSame(now, 'month') && 'hidden'
                  }}
                />
                <span>{props.month.format('MMMM YYYY')}</span>
                <Icon
                  name="caret right"
                  fitted
                  onClick={props.onNext}
                  style={{ cursor: 'pointer' }}
                />
              </MonthSelector>
              <Days>
                <Day>Su</Day>
                <Day>Mo</Day>
                <Day>Tu</Day>
                <Day>We</Day>
                <Day>Th</Day>
                <Day>Fr</Day>
                <Day>Sa</Day>
              </Days>
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {[...Array(Math.ceil((paddingDays + daysInMonth) / 7))].map(
            (x, i) => {
              return (
                <Table.Row key={i}>
                  {[...Array(7)].map((x, ii) => {
                    const day = i * 7 + (ii + 1) - paddingDays
                    const date = props.month.clone().add(day - 1, 'days')
                    const cellStyles = {
                      padding: 0,
                      paddingBottom: '14.286%',
                      position: 'relative',
                      cursor: 'pointer'
                    }
                    const disabled =
                      now.isAfter(date, 'day') ||
                      day > daysInMonth ||
                      day <= 0 ||
                      !props.timeSlots ||
                      !props.timeSlots.find(timeSlot =>
                        date.isBetween(
                          timeSlot.startAt,
                          timeSlot.endAt,
                          'day',
                          '[]'
                        )
                      )
                    return (
                      <Table.Cell
                        key={ii}
                        style={cellStyles}
                        disabled={disabled}
                      >
                        <InnerCell
                          selected={date.isSame(props.selectedDay, 'day')}
                          active={!disabled}
                          onClick={() => props.onSelect(date)}
                        >
                          {day <= daysInMonth && day > 0 && day}
                        </InnerCell>
                      </Table.Cell>
                    )
                  })}
                </Table.Row>
              )
            }
          )}
        </Table.Body>
      </Table>
    </Wrapper>
  )
}
