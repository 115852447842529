import React from 'react'
import { Segment } from 'semantic-ui-react'
import styled from 'styled-components'

const Body = styled.div`
  background: #333333;
  background-image: url(${props => props.background});
  background-size: cover;
  background-position: center;
  background-attachment: fixed;
  width: 100%;
  min-height: 100vh;
  padding: 50px 20px;
`
const Inner = styled.div`
  padding: 100px 150px;
  @media (max-width: 700px) {
    padding: 50px;
  }
  @media (max-width: 500px) {
    padding: 25px 25px 50px 25px;
  }
  @media (max-width: 400px) {
    padding: 10px 0 50px 0;
  }
  @media print {
    padding: 10px;
  }
`
const Outer = styled.div`
  max-width: 800px;
  margin: auto;
`
const BottomLinks = styled.div`
  text-align: center;
`

export default props => {
  return (
    <Body style={props.style} background={props.backgroundImage}>
      <Outer>
        <Segment>
          <Inner>{props.children}</Inner>
          <BottomLinks>
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hauntedticketing.com/terms-conditions.html"
            >
              Terms and Conditions
            </a>{' '}
            |{' '}
            <a
              target="_blank"
              rel="noopener noreferrer"
              href="https://www.hauntedticketing.com/privacy-policy.html"
            >
              Privacy Policy
            </a>
          </BottomLinks>
        </Segment>
      </Outer>
    </Body>
  )
}
