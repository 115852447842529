import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import Routes from './Routes'
import 'semantic-ui-css/semantic.min.css'
import 'react-datepicker/dist/react-datepicker.min.css'
import './index.css'
import { ToastContainer } from 'react-toastify'
import { StripeProvider } from 'react-stripe-elements'
import 'core-js/shim'

class App extends Component {
  render() {
    return (
      <StripeProvider apiKey={process.env.REACT_APP_STRIPE_PK}>
        <div>
          <Routes />
          <ToastContainer autoClose={15 * 1000} position="top-center" />
        </div>
      </StripeProvider>
    )
  }
}

ReactDOM.render(<App />, document.getElementById('root'))
