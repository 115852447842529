import React, { Component } from 'react'
import { Button, Grid, Icon, Message, Table } from 'semantic-ui-react'
import TimeSlot from 'components/TimeSlot'
import styled from 'styled-components'
import CompanyInfo from 'components/CompanyInfo'

const Map = styled.iframe`
  width: 100%;
  height: 150px;
  border: none;
  margin-bottom: 15px;
  @media print {
    display: none;
  }
`
const QRCode = styled.div`
  width: 50%;
  @media print {
    width: 30%;
    float: left;
    page-break-inside: avoid;
  }
`
const Flex = styled.div`
  display: flex;
  flex-wrap: wrap;
  @media print {
    display: block;
    &::after {
      content: '';
      clear: both;
      display: table;
    }
  }
`
const BottomButtons = styled(Grid)`
  @media print {
    display: none !important;
  }
`

export default class Ticket extends Component {
  constructor() {
    super()
    this.state = {
      transaction: null,
      ticketType: null,
      timeSlot: null
    }
    this.redirect = this.redirect.bind(this)
  }

  componentDidMount() {
    const routerState = this.props.history.location.state
    if (!routerState || !routerState.transaction) {
      return this.props.history.push(`/${this.props.company.frontEndUrl}`)
    }

    this.setState({
      transaction: routerState.transaction,
      ticketType: routerState.ticketType,
      timeSlot: routerState.timeSlot
    })
  }

  redirect() {
    this.props.history.push(`/${this.props.company.frontEndUrl}`)
  }

  render() {
    const { transaction, ticketType, timeSlot } = this.state
    const company = this.props.company
    if (!transaction || !ticketType) return null

    const searchQuery = encodeURI(
      `${company.address} ${company.city}, ${company.state} ${company.zipCode}`
    )
    return (
      <div>
        <CompanyInfo
          company={company}
          hideAdditionalInfo
          header="Print or screenshot this ticket, and have it with you when you arrive."
        />
        <div style={{ fontSize: 26, textAlign: 'center', marginBottom: 5 }}>
          {transaction.numberOfTickets} Ticket(s)
        </div>
        <div
          style={{
            fontSize: 18,
            textAlign: 'center',
            marginBottom: timeSlot && 25
          }}
        >
          {ticketType.name}
        </div>
        {timeSlot && <TimeSlot timeSlot={timeSlot} />}
        <br />

        {company.additionalTicketInfo && (
          <Message>{company.additionalTicketInfo}</Message>
        )}

        <Table style={{ marginTop: 0 }} striped unstackable celled>
          <Table.Body>
            <Table.Row>
              <Table.Cell>Subtotal</Table.Cell>
              <Table.Cell textAlign="right">
                ${transaction.subtotal.toFixed(2)}
              </Table.Cell>
            </Table.Row>
            {Boolean(transaction.discount) && (
              <Table.Row>
                <Table.Cell>Discount</Table.Cell>
                <Table.Cell textAlign="right">
                  - ${transaction.discount.toFixed(2)}
                </Table.Cell>
              </Table.Row>
            )}
            {company.passFees && (
              <Table.Row>
                <Table.Cell>Convenience Fee</Table.Cell>
                <Table.Cell textAlign="right">
                  ${transaction.fees.toFixed(2)}
                </Table.Cell>
              </Table.Row>
            )}
            {Boolean(transaction.salesTax) && (
              <Table.Row>
                <Table.Cell>Sales Tax</Table.Cell>
                <Table.Cell textAlign="right">
                  ${transaction.salesTax.toFixed(2)}
                </Table.Cell>
              </Table.Row>
            )}
            <Table.Row style={{ fontWeight: 'bold' }}>
              <Table.Cell>Total</Table.Cell>
              <Table.Cell textAlign="right">
                ${transaction.orderTotal.toFixed(2)}
              </Table.Cell>
            </Table.Row>
          </Table.Body>
        </Table>

        <Flex>
          {transaction.tickets.map((ticket, idx) => (
            <QRCode key={ticket.id}>
              <div style={{ textAlign: 'center', fontWeight: 'bold' }}>
                Ticket #{idx + 1}
              </div>
              <img
                style={{ width: '100%' }}
                alt="QR Code"
                src={`${process.env.REACT_APP_API_URL}/transactions/qr-code/${ticket.id}`}
              />
            </QRCode>
          ))}
        </Flex>

        <Map
          title="Google Maps"
          src={`https://www.google.com/maps/embed/v1/place?key=${process.env.REACT_APP_GOOGLE_MAPS_API}&q=${searchQuery}`}
          allowFullScreen
        />

        <BottomButtons columns={2} stackable>
          <Grid.Column>
            <Button fluid onClick={() => window.print()} color="blue">
              <Icon name="print" /> Print
            </Button>
          </Grid.Column>
          <Grid.Column>
            <Button fluid onClick={this.redirect} color="blue">
              <Icon name="ticket" /> New Order
            </Button>
          </Grid.Column>
        </BottomButtons>
        <p style={{ textAlign: 'center', marginTop: 20 }}>
          <a href={company.website}>Return to the {company.name}</a>
        </p>
      </div>
    )
  }
}
