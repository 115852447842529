import React from 'react'
import { Button, Input, Select } from 'semantic-ui-react'
import { CardElement } from 'react-stripe-elements'
import styled from 'styled-components'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

const CardInputWrapper = styled.div`
  border: solid 1px
    ${props => (props.focused ? '#85b7d9' : 'rgba(34, 36, 38, 0.15)')};
  padding: 0.67857143em 1em;
  border-radius: 0.28571429rem;
`
const Wrapper = styled.div`
  margin: auto;
`

export default class PaymentForm extends React.Component {
  constructor() {
    super()
    this.state = {
      marketingMediums: [],
      focused: false,
      complete: false
    }
  }

  componentDidMount() {
    axios()
      .get('/marketing-mediums')
      .then(mediums =>
        this.setState({
          marketingMediums: mediums.map(medium => ({
            text: medium.name,
            value: medium.id
          }))
        })
      )
      .catch(e => toast.error(e.message))
  }

  render() {
    return (
      <Wrapper>
        <br />
        <Input
          fluid
          value={this.props.customerName}
          name="customerName"
          onChange={this.props.handleInputChange}
          placeholder="Full Name"
        />
        <br />
        <Input
          fluid
          value={this.props.customerEmail}
          name="customerEmail"
          onChange={this.props.handleInputChange}
          placeholder="Email Address"
        />
        <br />
        <Select
          fluid
          placeholder="How did you hear about us?"
          options={this.state.marketingMediums}
          value={this.props.marketingMediumId}
          name="marketingMediumId"
          onChange={this.props.handleInputChange}
        />
        <br />
        {this.props.showCreditCard && (
          <React.Fragment>
            <CardInputWrapper focused={this.state.focused}>
              <CardElement
                onFocus={() => this.setState({ focused: true })}
                onBlur={() => this.setState({ focused: false })}
                onChange={e => this.setState({ complete: e.complete })}
              />
            </CardInputWrapper>
            <br />
          </React.Fragment>
        )}
        <Button
          fluid
          onClick={() => {
            if (!this.state.complete && this.props.showCreditCard) {
              toast.error(
                'Please fill out all of your credit card info including your zip code.'
              )
            } else {
              this.props.submit()
            }
          }}
          color="blue"
          loading={this.props.loading}
        >
          Purchase
        </Button>
      </Wrapper>
    )
  }
}
