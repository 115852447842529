import React, { Component } from 'react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import { injectStripe, Elements } from 'react-stripe-elements'
import QuantitySelector from './components/QuantitySelector'
import BalanceSheet from './components/BalanceSheet'
import PaymentForm from './components/PaymentForm'
import TimeSlot from 'components/TimeSlot'
import CompanyInfo from 'components/CompanyInfo'

class Checkout extends Component {
  constructor(props) {
    super(props)

    this.state = {
      customerName: '',
      customerEmail: '',
      expectedTotal: 0,
      numberOfTickets: 1,
      marketingMediumId: '',
      promoCodeId: '',
      debounce: null,
      subtotal: 0,
      fees: 0,
      salesTax: 0,
      discount: 0,
      marketingMediums: [],
      focused: false,
      loading: false,
      ticketType: undefined,
      timeSlot: undefined,
      minimumTicketCount: 1
    }

    this.calculate = this.calculate.bind(this)
    this.handleInputChange = this.handleInputChange.bind(this)
    this.submit = this.submit.bind(this)
    this.updateTicketCount = this.updateTicketCount.bind(this)
  }

  componentDidMount() {
    const routerState = this.props.history.location.state
    if (
      !routerState ||
      !routerState.ticketType ||
      (this.props.company.usesTimedTicketing && !routerState.timeSlot)
    ) {
      return this.props.history.push(`/${this.props.company.frontEndUrl}`)
    }

    const minimumTicketCount = routerState.ticketType.minimumTicketCount || 1
    this.setState(
      {
        minimumTicketCount: minimumTicketCount,
        numberOfTickets: minimumTicketCount,
        ticketType: routerState.ticketType,
        timeSlot: routerState.timeSlot
      },
      this.calculate
    )
  }

  updateTicketCount(additive) {
    if (
      this.state.numberOfTickets === this.state.minimumTicketCount &&
      additive < 0
    ) {
      return
    }

    window.clearTimeout(this.state.debounce)
    this.setState({
      numberOfTickets: this.state.numberOfTickets + additive,
      pricesLoading: true,
      debounce: setTimeout(this.calculate, 500)
    })
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  calculate() {
    axios()
      .post('/transactions/price', {
        companyId: this.props.company.id,
        numberOfTickets: this.state.numberOfTickets,
        ticketTypeId: this.state.ticketType.id,
        promoCodeId: this.state.promoCodeId || undefined
      })
      .then(totals => {
        this.setState({
          subtotal: totals.subtotal,
          fees: totals.fees,
          expectedTotal: totals.orderTotal,
          salesTax: totals.salesTax,
          discount: totals.discount
        })
      })
      .catch(e => toast.error(e.message))
  }

  async submit() {
    try {
      if (this.state.loading) return
      if (
        !this.state.customerName ||
        !this.state.customerEmail ||
        !this.state.marketingMediumId
      ) {
        return toast.error('Please complete all required fields.')
      }
      this.setState({ loading: true })

      const stripeToken =
        this.state.expectedTotal > 0 &&
        (await this.props.stripe
          .createToken({ name: this.state.customerName })
          .then(response => {
            return response.token.id
          }))

      await axios()
        .post('/transactions', {
          companyId: this.props.company.id,
          customerName: this.state.customerName,
          customerEmail: this.state.customerEmail,
          stripeToken: stripeToken || undefined,
          expectedTotal: this.state.expectedTotal,
          numberOfTickets: this.state.numberOfTickets,
          marketingMediumId: this.state.marketingMediumId,
          ticketTypeId: this.state.ticketType.id,
          promoCodeId: this.state.promoCodeId || undefined,
          timeSlotId: this.state.timeSlot && this.state.timeSlot.id
        })
        .then(transaction => {
          this.props.history.push({
            pathname: `/${this.props.company.frontEndUrl}/ticket`,
            state: {
              transaction: {
                discount: this.state.discount,
                subtotal: this.state.subtotal,
                ...transaction
              },
              ticketType: this.state.ticketType,
              timeSlot: this.state.timeSlot
            }
          })
        })
    } catch (e) {
      this.setState({ loading: false })
      if (e.code === 'incorrect_zip') {
        return toast.error(
          e.message +
            ' Any charges to your card will be removed within 72 hours.'
        )
      }
      toast.error(e.message)
    }
  }

  render() {
    const { numberOfTickets, ticketType, timeSlot } = this.state
    if (!ticketType) return null
    return (
      <div>
        <CompanyInfo company={this.props.company} />
        <br />
        <p style={{ textAlign: 'center', fontWeight: 'bold', fontSize: 18 }}>
          {ticketType.name} - ${ticketType.price.toFixed(2)}
        </p>
        {timeSlot && <TimeSlot timeSlot={timeSlot} />}
        <QuantitySelector
          ticketType={ticketType}
          numberOfTickets={numberOfTickets}
          updateTicketCount={this.updateTicketCount}
        />
        <BalanceSheet
          subtotal={this.state.subtotal}
          fees={this.state.fees}
          salesTax={this.state.salesTax}
          discount={this.state.discount}
          expectedTotal={this.state.expectedTotal}
          company={this.props.company}
          ticketType={ticketType}
          timeSlotId={timeSlot && timeSlot.id}
          updatePromoCodeId={promoCodeId =>
            this.setState({ promoCodeId }, this.calculate)
          }
          updateMinimumTicketCount={minimumTicketCount =>
            this.setState({
              minimumTicketCount:
                this.state.ticketType.minimumTicketCount < minimumTicketCount
                  ? minimumTicketCount
                  : this.state.minimumTicketCount,
              numberOfTickets:
                this.state.numberOfTickets < minimumTicketCount
                  ? minimumTicketCount
                  : this.state.numberOfTickets
            })
          }
        />
        <PaymentForm
          handleInputChange={this.handleInputChange}
          submit={this.submit}
          customerEmail={this.state.customerEmail}
          marketingMediumId={this.state.marketingMediumId}
          customerName={this.state.customerName}
          loading={this.state.loading}
          showCreditCard={this.state.expectedTotal > 0}
        />
      </div>
    )
  }
}

const StripeInjectedCheckout = injectStripe(Checkout)

export default props => {
  return (
    <Elements>
      <StripeInjectedCheckout {...props} />
    </Elements>
  )
}
