import React from 'react'
import { Form, List, Icon, Divider } from 'semantic-ui-react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'

export default class BalanceSheet extends React.Component {
  constructor() {
    super()
    this.state = {
      promoCode: '',
      promoCodeError: false
    }
    this.handleInputChange = this.handleInputChange.bind(this)
    this.findPromoCode = this.findPromoCode.bind(this)
    this.clearPromoCode = this.clearPromoCode.bind(this)
  }

  findPromoCode() {
    if (!this.state.promoCode) return
    axios()
      .get(`/promo-codes/${this.state.promoCode}`, {
        params: {
          companyId: this.props.company.id,
          ticketTypeId: this.props.ticketType.id,
          timeSlotId: this.props.timeSlotId || undefined
        }
      })
      .then(promoCode => {
        this.setState({
          promoCode: promoCode.code,
          promoCodeError: false
        })
        this.props.updatePromoCodeId(promoCode.id)
        this.props.updateMinimumTicketCount(promoCode.numberOfTickets)
      })
      .catch(e => {
        this.setState({ promoCodeError: true })
        toast.error(e.message)
      })
  }

  handleInputChange(event, data) {
    this.setState({
      [event.target.name || data.name]: event.target.value || data.value
    })
  }

  clearPromoCode() {
    this.setState({ promoCode: '' })
    this.props.updatePromoCodeId()
    this.props.updateMinimumTicketCount(
      this.props.ticketType.minimumTicketCount || 1
    )
  }

  render() {
    return (
      <List size="large" verticalAlign="bottom">
        <List.Item>
          <List.Content floated="left">Subtotal</List.Content>
          <List.Content floated="right">
            ${this.props.subtotal.toFixed(2)}
          </List.Content>
        </List.Item>
        {this.props.company.passFees && (
          <List.Item>
            <List.Content floated="left">Convenience Fee</List.Content>
            <List.Content floated="right">
              ${this.props.fees.toFixed(2)}
            </List.Content>
          </List.Item>
        )}
        {Boolean(this.props.salesTax) && (
          <List.Item>
            <List.Content floated="left">Sales Tax</List.Content>
            <List.Content floated="right">
              ${this.props.salesTax.toFixed(2)}
            </List.Content>
          </List.Item>
        )}
        {!this.props.discount && (
          <List.Item>
            <Divider />
            <Form onSubmit={this.findPromoCode}>
              <Form.Input
                action={{
                  content: 'Apply',
                  color: this.state.promoCodeError ? 'red' : undefined
                }}
                size="small"
                value={this.state.promoCode}
                error={this.state.promoCodeError}
                name="promoCode"
                placeholder="Promo Code"
                autoComplete="off"
                onChange={this.handleInputChange}
              />
            </Form>
          </List.Item>
        )}
        {Boolean(this.props.discount) && (
          <List.Item>
            <List.Content floated="left">
              {Boolean(this.props.discount) && (
                <Icon
                  name="close"
                  style={{ cursor: 'pointer' }}
                  onClick={this.clearPromoCode}
                />
              )}
              {this.state.promoCode}
            </List.Content>
            <List.Content floated="right">
              - ${this.props.discount.toFixed(2)}
            </List.Content>
          </List.Item>
        )}
        <List.Item>
          <Divider />
          <List.Content floated="left">
            <strong>Total</strong>
          </List.Content>
          <List.Content floated="right">
            <strong>${this.props.expectedTotal.toFixed(2)}</strong>
          </List.Content>
        </List.Item>
      </List>
    )
  }
}
