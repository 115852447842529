import React, { Component } from 'react'
import axios from 'helpers/axios.helper'
import { toast } from 'react-toastify'
import TicketTypes from 'components/TicketTypes'
import CompanyInfo from 'components/CompanyInfo'

export default class TicketTypeSelector extends Component {
  constructor() {
    super()
    this.state = {
      ticketTypes: [],
      loading: false
    }
    this.moveToCheckout = this.moveToCheckout.bind(this)
  }

  componentDidMount() {
    axios()
      .get(`/ticket-types/${this.props.company.id}`)
      .then(ticketTypes => this.setState({ ticketTypes }))
      .catch(e => toast.error(e.message))
  }

  moveToCheckout(ticketType) {
    this.props.history.push({
      pathname: `/${this.props.company.frontEndUrl}/checkout`,
      state: {
        ticketType
      }
    })
  }

  render() {
    return (
      <div>
        <CompanyInfo company={this.props.company} />
        <TicketTypes
          ticketTypes={this.state.ticketTypes}
          onSelect={this.moveToCheckout}
        />
      </div>
    )
  }
}
