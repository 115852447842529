import React from 'react'
import { Card, Label, Button } from 'semantic-ui-react'
import TimeSlot from 'components/TimeSlot'

export default function TicketTypes(props) {
  const timeSlot = props.timeSlot
  const ticketTypes =
    timeSlot && timeSlot.ticketTypes.length
      ? timeSlot.ticketTypes
      : props.ticketTypes
  return (
    <Card fluid>
      {timeSlot && (
        <React.Fragment>
          <Card.Content style={{ background: '#e6e6e6' }}>
            <TimeSlot timeSlot={timeSlot} />
          </Card.Content>
          {timeSlot.numberAvailable !== null && timeSlot.numberLeft < 100 && (
            <Card.Content
              textAlign="center"
              style={{
                padding: '.3em',
                background: timeSlot.numberLeft ? '#737373' : '#d92626',
                color: '#FFF',
                fontWeight: 'bold'
              }}
            >
              {timeSlot.numberLeft
                ? `${timeSlot.numberLeft} Tickets Left`
                : 'Sold Out'}
            </Card.Content>
          )}
        </React.Fragment>
      )}

      {ticketTypes.map(ticketType => (
        <Card.Content key={ticketType.id}>
          <Card.Header
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              flexWrap: 'wrap'
            }}
          >
            <div>{ticketType.name}</div>
            <div style={{ flexShrink: 0 }}>
              {ticketType.minimumTicketCount > 1 && (
                <Label size="small">
                  {ticketType.minimumTicketCount} Ticket Minimum
                </Label>
              )}
            </div>
          </Card.Header>
          <Card.Meta>${ticketType.price.toFixed(2)} </Card.Meta>
          <Card.Description>
            <Button
              onClick={() => props.onSelect(ticketType, timeSlot)}
              disabled={
                timeSlot &&
                timeSlot.numberAvailable !== null &&
                !timeSlot.numberLeft
              }
              color="blue"
              labelPosition="right"
              icon="angle right"
              size="small"
              fluid
              content="Select"
            />
          </Card.Description>
        </Card.Content>
      ))}
    </Card>
  )
}
