import React from 'react'
import Wrapper from 'components/Wrapper'
import { Button } from 'react-scroll'

export default function CatchAll(props) {
  return (
    <Wrapper style={{ textAlign: 'center' }}>
      <h1 style={{ fontSize: 80 }}>404</h1>
      <h2>Page Not Found</h2>
      <p>
        <Button
          onClick={() => window.history.go(-1)}
          style={{ cursor: 'pointer' }}
        >
          Go back
        </Button>
        , or view the{' '}
        <a href="https://www.hauntedticketing.com/directory.html">
          haunted attraction directory
        </a>
        .
      </p>
    </Wrapper>
  )
}
