import React from 'react'
import styled from 'styled-components'
import { Icon, Button } from 'semantic-ui-react'

const Wrapper = styled.div`
  margin: 30px 0 20px 0;
  text-align: center;
`
const Count = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 5px;
`
const Operator = styled.div`
  display: flex;
  align-items: center;
`
const Number = styled.div`
  font-size: 30px;
  padding: 0 20px;
  border: solid 1px #e0e1e2;
  min-width: 100px;
  text-align: center;
`

export default function(props) {
  return (
    <Wrapper>
      <Count>
        <Operator onClick={() => props.updateTicketCount(-1)}>
          <Button attached="left">
            <Icon style={{ margin: 0 }} name="minus" />
          </Button>
        </Operator>
        <Number>{props.numberOfTickets}</Number>
        <Operator onClick={() => props.updateTicketCount(1)}>
          <Button attached="right">
            <Icon style={{ margin: 0 }} name="plus" />
          </Button>
        </Operator>
      </Count>
      Ticket(s)
    </Wrapper>
  )
}
