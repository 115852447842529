import React from 'react'
import moment from 'moment-timezone'
import { Icon } from 'semantic-ui-react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  text-align: center;
  justify-content: space-around;
  max-width: 400px;
  margin: auto;
`

const Arrow = styled.div`
  display: flex;
  align-items: center;
  padding: 7px;
`

export default function(props) {
  return (
    <Wrapper>
      <div>
        <strong>{moment(props.timeSlot.startAt).format('h:mm A')}</strong>
        <br />
        {moment(props.timeSlot.startAt).format('LL')}
      </div>
      <Arrow>
        <Icon name="long arrow right" />
      </Arrow>
      <div>
        <strong>{moment(props.timeSlot.endAt).format('h:mm A')}</strong>
        <br />
        {moment(props.timeSlot.endAt).format('LL')}
      </div>
    </Wrapper>
  )
}
