import React, { Component } from 'react'
import { Switch, Route, BrowserRouter } from 'react-router-dom'
import axios from 'helpers/axios.helper'
import moment from 'moment-timezone'
import { toast } from 'react-toastify'
import { Helmet } from 'react-helmet'
import { Dimmer, Loader } from 'semantic-ui-react'

import Wrapper from 'components/Wrapper'
import TimeSlotSelector from './TimeSlotSelector'
import Checkout from './Checkout'
import Ticket from './Ticket'
import CatchAll from './CatchAll'
import InactiveCompany from './InactiveCompany'
import TicketTypeSelector from './TicketTypeSelector'
import RequestRefund from './RequestRefund'

function CustomRouter(props) {
  window.scrollTo(0, 0)
  return (
    <Wrapper backgroundImage={props.company.ticketPortalBackground}>
      <Helmet>
        <title>Buy tickets for the {props.company.name}!</title>
      </Helmet>
      <Route {...props} />
    </Wrapper>
  )
}

function LandingPage(props) {
  if (!props.company.isLive) {
    return <InactiveCompany {...props} company={props.company} />
  } else if (props.company.usesTimedTicketing) {
    return <TimeSlotSelector {...props} company={props.company} />
  } else {
    return <TicketTypeSelector {...props} company={props.company} />
  }
}

class Routes extends Component {
  constructor() {
    super()
    this.state = {
      company: null,
      loading: true
    }
  }

  componentDidMount() {
    axios()
      .get(`/companies/${window.location.pathname.split('/')[1]}`)
      .then(company => {
        moment.tz.setDefault(company.timeZone)
        this.setState({ company, loading: false })
      })
      .catch(e => toast.error(e.message))
  }

  render() {
    if (this.state.loading) {
      return (
        <Dimmer active={this.state.loading}>
          <Loader />
        </Dimmer>
      )
    }
    return (
      <BrowserRouter>
        <Switch>
          <Route
            exact
            path="/"
            render={() =>
              (window.location =
                'https://www.hauntedticketing.com/directory.html')
            }
          />
          {this.state.company && (
            <CustomRouter
              exact
              company={this.state.company}
              path="/:frontEndUrl"
              render={props => (
                <LandingPage {...props} company={this.state.company} />
              )}
            />
          )}
          {this.state.company && (
            <CustomRouter
              exact
              company={this.state.company}
              path="/:frontEndUrl/checkout"
              render={props => (
                <Checkout {...props} company={this.state.company} />
              )}
            />
          )}
          {this.state.company && (
            <CustomRouter
              exact
              company={this.state.company}
              path="/:frontEndUrl/ticket"
              render={props => (
                <Ticket {...props} company={this.state.company} />
              )}
            />
          )}
          <Route exact path="/request-refund/:id" component={RequestRefund} />
          <Route component={CatchAll} />
        </Switch>
      </BrowserRouter>
    )
  }
}

export default Routes
